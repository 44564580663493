import { Dispatch } from "@reduxjs/toolkit";
import { routePlanningSlice } from "./routePlanningSlice";
import * as defaultActions from "../../../../../redux/_common";
import { get, post, put  } from "../../../../../api/requester";
import { getErrorMessage } from "../../../../../redux/_helpers";
import { ILocation, ITaskFilterTab, IValues } from "../../_interfaces/routeCreation";
import axios from "axios";
import { ISavedToursPlan, IStop, ITour, IToursPlan, IVehicleType } from "../../_interfaces/reduxState";
import { ROUTE_COLORS } from "../../constants";
import * as fields from '../../constants';
import { constructInitialFilters } from "../../../../components/common/filters/utils";


const moduleUrl = '/api/routeplanning';
const { actions } = routePlanningSlice;
export const setValidationErrors = actions.setValidationErrors;

export const getItems = (params:any) => {
    return defaultActions.getItems(moduleUrl, actions, params);
}

export const clearAll = () => defaultActions.clearAll(actions);
export const clearRouteItem = () => defaultActions.clearItem(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);
export const setFilter = (filters: any[]) => defaultActions.setFilters(filters, actions);
export const getFilters = () => async (dispatch:Dispatch) => {
    try {
        const rolesFilters:any[] = await get(`/api/Authorization/Roles/complexSearch`);

        dispatch(actions.setFilter(
            constructInitialFilters([
              ...(rolesFilters || []),
            ])
        ));
    } catch (error) {
        const errors = getErrorMessage(error, "Filters could not be loaded!");
        dispatch(actions.catchError({ errors, callType: "actionsLoading", errorProp: "error" }));
    }
}


export const getRangeOptions = () => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setRangeOptionsLoading(true));

        const ranges = [
            {
                id: '1',
                code: 'TER',
                name: 'Territory'
            },
            {
                id: '2',
                code: 'CITY',
                name: 'City'
            },
            {
                id: '3',
                code: 'REG',
                name: 'Region'
            },
            {
                id: '4',
                code: 'CO',
                name: 'Country'
            }
        ]
        // const ranges = await get(``)

        dispatch(actions.setRangeOptions(ranges));
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while fetching route ranges!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
    } finally {
        dispatch(actions.setRangeOptionsLoading(false));
    }
}

export const getLocations = (params:ITaskFilterTab) => async (dispatch:Dispatch) => {
    try {
        const transformedParams = {
            includeAssigned: params[fields.INCLUDE_ASSIGNED] === '0' ? false : true,
            Date: params[fields.DATE]
                ? params[fields.DATE].toISOString()
                : new Date().toISOString(),
            ...(params[fields.RANGE]?.id === '1' && params[fields.TERRIOTRY]
                    ? {TerritoryId: params[fields.TERRIOTRY]?.id}
                    : {}
            ),
            ...(params[fields.RANGE]?.id === '2' && params[fields.TERRIOTRY]
                    ? {CityId: params[fields.TERRIOTRY]?.id}
                    : {}
            ),
            ...(params[fields.RANGE]?.id === '3' && params[fields.TERRIOTRY]
                    ? {RegionId: params[fields.TERRIOTRY]?.id}
                    : {}
            ),
            ...(params[fields.RANGE]?.id === '4' && params[fields.TERRIOTRY]
                    ? {CountryId: params[fields.TERRIOTRY]?.id}
                    : {}
            ),
            ...(params[fields.TASK_TYPES] && params[fields.TASK_TYPES].length > 0
                    ?   {...params[fields.TASK_TYPES].reduce((a, {id}, i) => ({
                        ...a,
                        [`TaskTypeIds[${i}]`]: id
                    }), {})}
                    : {}),
            ...(params[fields.SEGMENT] && params[fields.SEGMENT].length > 0
                    ? {...params[fields.SEGMENT].reduce((a, {id}, i) => ({
                        ...a,
                        [`SegmentIds[${i}]`]: id
                    }), {})}
                    : {}
            )
            
        }
        dispatch(actions.setLocationsLoading(true));
        const locations = await get(`${moduleUrl}/taskSelection`, transformedParams) 

        const transformedLocations:ILocation[] = locations.map((location:any, i:number) => {
            return {
                id: location.customerId + i,
                description: location.customerName,
                address: location.address,
                country: location.country,
                region: location.region,
                customerId: location.customerId,
                customerName: location.customerName,
                location: [location.latitude, location.longitude],
                isActive: true,
                tasks: location.tasks.map((task:any, i:number) => {
                    return {
                        id: i,
                        taskType: task.typeName,
                        taskCode: task.typeCode,
                        address: location.address,
                        description: location.customerName,
                        duration: 15,
                        location: [location.latitude, location.longitude],
                        isActive: true,
                    
                        plannedFromUTC: task.plannedFromUTC,
                        plannedToUTC: task.plannedToUTC,
                        taskCustomerId: task.taskCustomerId,
                    }
                })
            }
        })

        dispatch(actions.setLocations(transformedLocations))

        return true;
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while fetching tasks!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
        return false;
    } finally {
        dispatch(actions.setLocationsLoading(false));
    }
}

export const setTourPlan = (tours:IToursPlan | null) => actions.setTourPlan(tours)
export const setSavedTourPlan = (tours:ISavedToursPlan | null) => actions.setSavedTour(tours)
export const setLocations = (locations:ILocation[]) => actions.setLocations(locations);
export const setSelectedTour = (tour:ITour | null) => actions.setSelectedTour(tour);
export const setUserLocation = (location:{lat: number, lng:number, isSet:boolean}) => actions.setUserLocation(location)

export const getTasks = (params:any) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setTasksLoading(true));

        // dispatch(actions.setTasks(tasks));
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while fetching tasks!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
    } finally {
        dispatch(actions.setTasksLoading(false));
    }
}

export const getVehicleTypes = () => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setVehicleTypesLoading(true));

        const response:IVehicleType[] = await get(`${moduleUrl}/vehicleTypes`);

        dispatch(actions.setVehicleTypes(response.filter(({isActive}) => isActive)));
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while fetching vehicle types !");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
    } finally {
        dispatch(actions.setVehicleTypesLoading(false))
    }
}

const createFilters = (problem:IValues) => {
    return {
        vehicleTypeId: problem[fields.ROUTE_SETUP_TAB][fields.VEHICLE_TYPE]?.[fields.ID],
        numberOfVehicles: problem[fields.ROUTE_SETUP_TAB][fields.NUMBER_OF_VEHICLES],
        capacityOfVehicles: problem[fields.ROUTE_SETUP_TAB][fields.CAPACITY_OF_VEHICLE],
        ...(problem[fields.ROUTE_SETUP_TAB][fields.ENABLE_MAX_DISTANCE]
            ? {
                maxDistanceInKm: problem[fields.ROUTE_SETUP_TAB][fields.MAX_DISTANCE]
            }
            : {}
        ),
        ...(problem[fields.ROUTE_SETUP_TAB][fields.ENABLE_MAX_SHIFT_TIME]
            ? {
                maxShiftTimeInHours: problem[fields.ROUTE_SETUP_TAB][fields.MAX_SHIFT_TIME]
            }
            : {}
        ),
        ...(problem[fields.ROUTE_SETUP_TAB][fields.START_LOCATION]
            ? {
                startAddress: problem[fields.ROUTE_SETUP_TAB][fields.START_LOCATION],
            } : {}
        ),
        ...(problem[fields.ROUTE_SETUP_TAB][fields.TOUR_END] === 0
            ? {
                endAddress: problem[fields.ROUTE_SETUP_TAB][fields.START_LOCATION],
            }
            : {} 
        ),
        ...(problem[fields.ROUTE_SETUP_TAB][fields.TOUR_END] === 1
            ? {
                endAddress: problem[fields.TASK_SELECTION_TAB].findLast(({isActive}) => isActive)?.address
                    || problem[fields.TASK_SELECTION_TAB][problem[fields.TASK_SELECTION_TAB].length - 1].address,
            }
            : {} 
        ),
        ...(problem[fields.ROUTE_SETUP_TAB][fields.TOUR_END] === 2
            ? {
                [fields.END_LOCATION]: problem[fields.ROUTE_SETUP_TAB][fields.END_LOCATION]
            }
            : {}
        ),
        ...(problem[fields.ROUTE_SETUP_TAB][fields.SHIFT_START_TIME]
            ? {
                shiftStart: problem[fields.ROUTE_SETUP_TAB][fields.SHIFT_START_TIME].toISOString()
            } : {}
        ),
        ...(problem[fields.ROUTE_SETUP_TAB][fields.SHIFT_END_TIME]
            ? {
                shiftEnd: problem[fields.ROUTE_SETUP_TAB][fields.SHIFT_END_TIME].toISOString()
            } : {}
        ),
    }
}


export const fetchProblem = (problem:IValues) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setTourPlanLoading(true));
        dispatch(actions.setTourPlanStatus('In Progress'));

        const taskSelection = problem[fields.TASK_SELECTION_TAB].reduce((tasks:{taskCustomerId:string, latitude:number, longitude:number}[], location:ILocation) => [
            ...tasks,
            ...location.tasks.filter((task) => task.isActive).map((task) => ({
                taskCustomerId: task.taskCustomerId,
                latitude: task.location[0],
                longitude: task.location[1],
            }))
        ], []);

        debugger;
        const payload = {
            ...createFilters(problem),
            taskSelection,
            ...(problem[fields.RELATIONS]?.length > 0 ? {
                relations: problem[fields.RELATIONS].map((relation) => ({
                    ...relation,
                    taskCustomerIds: relation.taskCustomerIds.filter((id:string) => 
                        taskSelection.some((t) => t.taskCustomerId === id))
                })).filter((r) => r.taskCustomerIds.length > 0)
            } : {})
        }

        const response:IToursPlan = await post(`${moduleUrl}/generateRoutes`, {}, payload);
        
        dispatch(actions.setTourPlan({
            ...response,
            tours: response.tours.map((tour, i:number) => ({
                ...tour,
                // color: ROUTE_COLORS[Math.floor(Math.random() * 12)]
                color: ROUTE_COLORS[i] ? ROUTE_COLORS[i] : ROUTE_COLORS[Math.floor(Math.random() * 12)]
            }))
        }));
        dispatch(actions.setSelectedTour(null));
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while generating route!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
    } finally {
        dispatch(actions.setTourPlanLoading(false));
    }
}

export const recalcSavedRoute = (problem:ISavedToursPlan) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setTourPlanLoading(true));
        dispatch(actions.setTourPlanStatus('In Progress'));

        const taskSelection = problem.stops.reduce((acc:{taskCustomerId:string, latitude:number, longitude:number}[], el:IStop) => {
            const { activities } = el;

            const newAactivities = activities.filter(({isArrival, isDeparture}) => !isArrival && !isDeparture);

            return [
                ...acc,
                ...newAactivities.map((a) => ({
                    latitude: a.latitude,
                    longitude: a.longitude,
                    taskCustomerId: a.taskCustomerId || a.task.taskCustomerId
                }))
            ]
        }, []);

        const payload = {
            ...problem.routeConfiguration,
            // ...(problem.relations ? {relations: problem.relations} : {}),
            ...(problem[fields.RELATIONS] && problem[fields.RELATIONS]?.length > 0 ? {
                relations: problem[fields.RELATIONS].map((relation) => ({
                    ...relation,
                    taskCustomerIds: relation.taskCustomerIds.filter((id:string) => 
                        taskSelection.some((t) => t.taskCustomerId === id))
                }))
            } : {}),
            shiftStart: problem.routeConfiguration.shiftStart + 'Z',
            shiftEnd: problem.routeConfiguration.shiftEnd + 'Z',
            [fields.NUMBER_OF_VEHICLES]: 1,
            capacityOfVehicle: null,//problem[fields.ROUTE_CONFIGURATION][fields.CAPACITY_OF_VEHICLE],
            taskSelection,
        }

        const response:IToursPlan = await post(`${moduleUrl}/generateRoutes`, {}, payload);
        
        dispatch(setSavedTourPlan({
            ...problem,
            [fields.DISTANCE_IN_METERS]: response.statistic[fields.DISTANCE_IN_METERS],
            [fields.DURATION_IN_SECONDS]: response.statistic[fields.DURATION_IN_SECONDS],
            stops: response.tours[0].stops,
        }));

    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while generating new route!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
    } finally {
        dispatch(actions.setTourPlanLoading(false));
    }
} 

export const fetchSavedRoute = (id:string) => async (dispatch:Dispatch) => {
    try {
        dispatch(actions.setTourPlanLoading(true));
        dispatch(actions.setTourPlanStatus('In Progress'));

        const result:ISavedToursPlan = await get(`${moduleUrl}/${id}`);

        dispatch(actions.setSelectedTour(null));
        dispatch(setSavedTourPlan({
            ...result,
            color: ROUTE_COLORS[0]
        }));
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while fetching route!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
    } finally {
        dispatch(actions.setTourPlanLoading(false));
    }
}

export const saveRoutePlan = (tours:IToursPlan, problem:IValues) => async (dispatch:Dispatch) => {
    try {
        const payload = {
            routes: tours.tours.map((tour) => ({
                plannedOnDate: problem[fields.TASK_SELECTION_FILTER_TAB][fields.DATE].toISOString(),
                routeRoleId: tour[fields.ROLES][fields.ID],
                assignedToUserId: tour[fields.ASSIGNED_TO_USER_ID][fields.ID],
                durationInSeconds: tour.statistic[fields.DURATION_IN_SECONDS],
                distanceInMeters: tour.statistic[fields.DISTANCE_IN_METERS],
                vehicleId: tour[fields.VEHICLE_ID],
                vehicleTypeId: tour[fields.TYPE_ID],
                routeConfiguration: {
                    startAddress: problem[fields.ROUTE_SETUP_TAB][fields.START_LOCATION],
                    endAddress: problem[fields.ROUTE_SETUP_TAB][fields.END_LOCATION],
                    ...(problem[fields.ROUTE_SETUP_TAB][fields.TOUR_END] === 0
                        ? {
                            endAddress: problem[fields.ROUTE_SETUP_TAB][fields.START_LOCATION],
                        }
                        : {} 
                    ),
                    ...(problem[fields.ROUTE_SETUP_TAB][fields.TOUR_END] === 1
                        ? {
                            endAddress: problem[fields.TASK_SELECTION_TAB][problem[fields.TASK_SELECTION_TAB].length - 1].address,
                        }
                        : {} 
                    ),
                    vehicleTypeId: tour.typeId,
                    capacityOfVehicle: null,//problem[fields.ROUTE_SETUP_TAB][fields.CAPACITY_OF_VEHICLE],
                    ...(problem[fields.ROUTE_SETUP_TAB][fields.MAX_DISTANCE]
                        ? {maxDistanceInMeters: problem[fields.ROUTE_SETUP_TAB][fields.MAX_DISTANCE] * 1000}
                        : {}
                    ),
                    ...(problem[fields.ROUTE_SETUP_TAB][fields.MAX_SHIFT_TIME]
                        ? {maxShiftTimeInSeconds: problem[fields.ROUTE_SETUP_TAB][fields.MAX_SHIFT_TIME] * 60 * 60}
                        : {}
                    ),
                    ...(problem[fields.ROUTE_SETUP_TAB][fields.SHIFT_START_TIME]
                        ? {shiftStart: problem[fields.ROUTE_SETUP_TAB][fields.SHIFT_START_TIME].toISOString()}
                        : {}
                    ),
                    ...(problem[fields.ROUTE_SETUP_TAB][fields.SHIFT_END_TIME]
                        ? {shiftEnd: problem[fields.ROUTE_SETUP_TAB][fields.SHIFT_END_TIME].toISOString()}
                        : {}
                    )
                },
                stops: tour.stops.map((stop) => ({
                    arrivalDateTime: stop.arrivalDateTime,
                    departureDateTime: stop.departureDateTime,
                    latitude: stop.latitude,
                    longitude: stop.longitude,
                    activities: stop.activities.map((activity) => ({
                        taskCustomerId: activity.task?.taskCustomerId || null,
                        isDeparture: activity.isDeparture,
                        isArrival: activity.isArrival,
                        latitude: activity.latitude,
                        longitude: activity.longitude,
                        startDateTime: activity.startDateTime,
                        endDateTime: activity.endDateTime,
                    }))
                }))
            }))
        }
        const res = await post(moduleUrl, {}, payload);
        dispatch(actions.setTourPlan(null));
        return res;
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while saving route!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
    } finally {
        
    }
}

export const cancelTours = (routesIds:string[]) => async (dispatch:Dispatch) => {
    try {
        const res = await post(`${moduleUrl}/cancel`, {}, {routesIds});
        return true
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while canceling route!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
    } finally {
        
    }
}

export const updateRoute = (tour:ISavedToursPlan) => async (dispatch:Dispatch) => {
    try {
        const payload = {
            [fields.ID]: tour[fields.ID],
            [fields.DURATION_IN_SECONDS]: tour[fields.DURATION_IN_SECONDS],
            [fields.DISTANCE_IN_METERS]: tour[fields.DISTANCE_IN_METERS],
            [fields.ROUTE_ROLE_ID]: tour[fields.ROUTE_ROLE_ID],
            [fields.ASSIGNED_TO_USER_ID]: tour[fields.ASSIGNED_TO_USER_ID],
            stops: tour.stops.map((stop) => ({
                routeId: tour[fields.ID],
                arrivalDateTime: stop.arrivalDateTime,
                departureDateTime: stop.departureDateTime,
                latitude: stop.latitude,
                longitude: stop.longitude,
                activities: stop.activities.map((activity) => ({
                    taskCustomerId: activity.task?.taskCustomerId,
                    isDeparture: activity.isDeparture,
                    isArrival: activity.isArrival,
                    latitude: activity.latitude,
                    longitude: activity.longitude,
                    startDateTime: activity.startDateTime,
                    endDateTime: activity.endDateTime,
                    task: activity.task
                }))
            }))
        }

        const result = await put(moduleUrl, {}, payload);
        return true;
    } catch (errors:any) {
        const error = getErrorMessage(errors, "Error occured while updating route!");
        dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "error" }));
        return false;
    }
}